import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import AppFrame from './components/AppFrame';
import Home from './components/Home';
import Donors from './components/Donors';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <div className="branding">
          <h1 className="gradient">
            <a className="gradient" href="/">Job Tools</a>
          </h1>
        </div>
        <div className="navigation">
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/resume-insights" element={<AppFrame src="https://resume-insights.jdavis.xyz" />} />
            <Route path="/cover-letter" element={<AppFrame src="https://cover-letter.jdavis.xyz" />} />
            <Route path="/donors" element={<Donors />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
