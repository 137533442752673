// src/components/Home.js
import React from 'react';

const Home = () => {
  return (
    <div className="home" align="center">
      <img alt="Job Tools" src="https://resume-insights.jdavis.xyz/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fbot.4e9238e6.png&w=256&q=75" />
      <h2 className="header-subtitle">Welcome to Job Tools!</h2>
      <div className="cell">
        <p className="left">Use AI generated resume insights and a job description specific cover letter to get your next job.</p>
        <p className="left">Job Tools was created by <a href="https://jdavis.xyz" target="_blank" rel="noreferrer">J. Davis</a> and is currently a free service. Consider making a donation if these tools help you land your dream job!</p>
        <p>
          <a href="https://donate.stripe.com/dR6aGv7yrgFi5UYeUU" className="button">Make a donation!</a>
        </p>
        <p>
          <small>🙏 Thank you to all the <a href="/donors">donors</a>!</small>
        </p>
      </div>
    </div>
  );
};

export default Home;
