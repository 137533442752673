import React from 'react';

const AppFrame = ({ src }) => {
  return (
    <div className="app-frame">
      <iframe src={src} title="App Frame" width="100%" height="600px" />
    </div>
  );
};

export default AppFrame;
