// src/components/Donors.js
import React from 'react';

const Donors = () => {
  return (
    <div className="home" align="center">
      <img alt="Job Tools" src="https://resume-insights.jdavis.xyz/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fbot.4e9238e6.png&w=256&q=75" />
      <h2 className="header-subtitle">💰 Donations keep Job Tools alive!</h2>
      <div className="cell">
        <p className="left">A special heartfelt thank you to all of our donors!</p>
        <p className="left">💖 Sandra Westlake</p>
        <p className="left">💖 Rajul Shah</p>
        <p className="left">💖 Alex Spitz</p>
        <p className="left">💖 Mara Abernathy</p>
        <p className="left">💖 Sandeep Maheshwari</p>
        <p>
          {
          // https://oaidalleapiprodscus.blob.core.windows.net/private/org-n0WYKhuVgHhbc5QZXaE2G8j0/user-WEGAplcVq13oGR5tQ9oYNmSL/img-3v3lBXk3cvIbcoLbIRUNrzVO.png?st=2024-08-07T21%3A23%3A09Z&se=2024-08-07T23%3A23%3A09Z&sp=r&sv=2023-11-03&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-08-06T23%3A32%3A59Z&ske=2024-08-07T23%3A32%3A59Z&sks=b&skv=2023-11-03&sig=h%2BLOQpRvVNWg8zP76fGKYAGVEM1qjPFCyD7VpFqLWKI%3D
          }
          <a href="https://donate.stripe.com/dR6aGv7yrgFi5UYeUU" className="button">Make a donation!</a>
        </p>
        <p>
          <small>🙏 Thank you to all the <a href="/donors">donors</a>!</small>
        </p>
      </div>
    </div>
  );
};

export default Donors;
