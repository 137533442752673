import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.css';

const Navigation = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navigation">
      <button 
        className="hamburger"
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        {isNavExpanded ? '✕' : '☰'}
      </button>
      <div 
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li><NavLink to="/" onClick={() => setIsNavExpanded(false)}>Home</NavLink></li>
          <li><NavLink to="/resume-insights" onClick={() => setIsNavExpanded(false)}>Resume Insights</NavLink></li>
          <li><NavLink to="/cover-letter" onClick={() => setIsNavExpanded(false)}>Cover Letter</NavLink></li>
          <li><NavLink to="/donors" onClick={() => setIsNavExpanded(false)}>Donors</NavLink></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
